noscript {
    display: block;
    margin-top: 30px;
    padding: 30px 15px;
    text-align: center;
}

@-webkit-keyframes bubbles {
    0% {
        @include transform-scale(1);
    }
    50% {
        @include transform-scale(1.5);
    }
    100% {
        @include transform-scale(1);
    }
}

@keyframes bubbles {
    0% {
        @include transform-scale(1);
    }
    50% {
        @include transform-scale(1.5);
    }
    100% {
        @include transform-scale(1);
    }
}

.preloader {
    @include transition-duration(0.5s);
    @include transition-property(opacity, z-index);
    @include transition-timing(linear);
    background-color: $preloader-background;
    bottom: 0;
    left: 0;
    opacity: 1;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
    .bubbles {
        bottom: 0;
        height: 20px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
        &::before,
        &::after {
            @include animation-duration(1s);
            @include animation-iteration-count(infinite);
            @include animation-name(bubbles);
            @include animation-timing-function(cubic-bezier(.65, .05, .36, 1));
            border-radius: 50%;
            content: "";
            height: 20px;
            position: absolute;
            width: 20px;
        }
        &::before {
            background-color: $preloader-color-2;
            left: -20px;
        }
        &::after {
            @include animation-delay(.5s);
            background-color: $preloader-color-3;
            right: -20px;
        }
    }
    &.disable {
        opacity: 0;
        z-index: -9999;
    }
}