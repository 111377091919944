.footer {
    background-color: $background-color-5;
    padding: 30px 0;
    h2 {
        color: $text-color-2;
        font-size: 48px;
        font-weight: 700;
        line-height: 42px;
        margin-bottom: 30px;
        text-align: center;
        span {
            color: $text-color-1;
            font-weight: 300;
        }
    }
    .social {
        margin: 0px 0 25px;
        text-align: center;
        a {
            background-color: $background-color-1;
            border-radius: 50%;
            display: inline-block;
            height: 44px;
            margin: 0 8px;
            width: 44px;
            i {
                color: $text-color-2;
                line-height: 44px;
                font-size: 26px;
            }
        }
    }
    p {
        color: $text-color-1;
        font-size: 14px;
        text-align: center;
        @media (min-width: $screen-sm-min) {
            text-align: left;
        }
    }
    .copyright {
        text-align: center;
    }
}