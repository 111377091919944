article {
    >header {
        background-color: $background-color-5;
        background-image: none;
        padding: 20px 0 30px;
        @media (min-width: $screen-sm-min) {
            // padding: 80px 0 30px;
        }
        h1 {
            color: $text-color-1;
            font-size: 48px;
            font-weight: 700;
        }
        .lead {
            color: $text-color-1;
            font-size: 16px;
            font-weight: 700;
        }
    }
    .article-main-content {
        padding-bottom: 30px;
        padding-top: 30px;
        h2 {
            color: $text-color-2;
            font-weight: 700;
            font-size: 42px;
        }
        .content-img {
            float: none !important;
            margin-bottom: 20px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 20px;
            &.pull-left {
                @media (min-width: $screen-sm-min) {
                    float: left !important;
                    margin-bottom: 10px;
                    margin-right: 30px;
                }
            }
            &.pull-right {
                @media (min-width: $screen-sm-min) {
                    float: right !important;
                    margin-bottom: 10px;
                    margin-left: 30px;
                }
            }
        }
    }
    >footer {
        .about-author {
            background-color: $background-color-3;
            box-shadow: -7px -7px 0 $background-color-4;
            padding-bottom: 15px;
            padding-top: 15px;
        }
        .author-image {
            img {
                border-radius: 50%;
                border: 4px solid $background-color-1;
            }
        }
        .about-author-info {
            color: $text-color-1;
            text-align: center;
            @media (min-width: $screen-md-min) {
                text-align: left;
            }
            .super-title {
                display: block;
                font-size: 18px;
            }
            .author-name {
                font-size: 30px;
            }
            a {
                color: $text-color-1;
            }
        }
    }
    .keyword {
        color: $text-color-4;
        display: inline-block;
        font-weight: bold;
        position: relative;
        .popup {
            bottom: 0;
            display: none;
            left: 0;
            margin: 0 auto;
            padding-bottom: 30px;
            position: absolute;
            right: 0;
            width: 160px;
            .wrapper {
                background-color: $background-color-5;
                display: block;
                padding: 5px 5px 0 5px;
                box-shadow: 0 0 5px 0 rgba($background-color-2, 0.8);
                &::after {
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-top: 12px solid #019b54;
                    bottom: 18px;
                    content: "";
                    left: 0;
                    margin: 0 auto;
                    position: absolute;
                    right: 0;
                    width: 20px;
                }
                .caption {
                    color: $text-color-1;
                    display: block;
                    font-weight: bold;
                    padding: 5px;
                    text-align: center;
                }
            }
        }
        &:hover .popup {
            display: block;
        }
    }
}

aside {
    .subpage-pagination {
        list-style-image: none;
        list-style-position: inside;
        list-style-type: none;
        margin-bottom: 0;
        margin-top: 30px;
        overflow: hidden;
        padding: 0;
        li {
            float: left;
            font-size: 14px;
            &:nth-child(1)::before,
            &:nth-child(1)::after {
                color: $text-color-2;
                content: "\f015";
                font-family: "FontAwesome";
                margin-right: 5px;
            }
            &:nth-child(1)::after {
                content: "\f101";
                margin-left: 5px;
                margin-right: 5px;
            }
            &:nth-child(2) {
                font-weight: 700;
            }
        }
    }
    &:nth-last-child(1) {
        .subpage-pagination {
            margin-bottom: 30px;
        }
    }
}

.products {
    >h2 {
        color: $text-color-4;
        font-weight: bold;
        text-align: center;
    }
    >div {
        margin-bottom: 60px;
    }
    .item {
        border: 1px solid $background-color-5;
        display: block;
        margin: 30px auto 0 auto;
        max-width: 360px;
        @media (min-width: $screen-sm-min) {
            width: 100%;
        }
        span {
            background-color: $background-color-5;
            color: $text-color-1;
            display: block;
            font-weight: bold;
            padding: 15px 20px;
            text-align: center;
            text-transform: uppercase;
        }
    }
}