/*Media queries*/

$screen-xs-min: 480px; // Extra small devices (mobile phones, 480px and up)
$screen-xs-max: 479px; // Extra small devices (mobile phones, 480px and up)
$screen-sm-min: 768px; // Small devices (tablets, 768px and up)
$screen-md-min: 992px; // Medium devices (desktops, 992px and up)
$screen-lg-min: 1200px; // Large devices (large desktops, 1200px

/*Paths to images*/

$image-path: "../img/";

/*Colors*/

$text-color-1: #ffffff;
$text-color-2: #000000;
$text-color-3: #0083cb;
$text-color-4: #019b54;
$text-color-5: #e20e87;
$background-color-1: #ffffff;
$background-color-2: #000000;
$background-color-3: #0083cb;
$background-color-4: rgba(0, 131, 203, 0.5);
$background-color-4a: rgba(0, 131, 203, 0.9);
$background-color-5: #019b54;
$background-color-6: rgba(1, 155, 84, 0.5);
$background-color-7: #e20e87;
$background-color-7a: rgba(180, 5, 105, 0.79);
$background-color-chart-bar-1: #a5dfff;
$background-color-chart-bar-2: #2b566e;
$background-navbar-1: rgba(0, 61, 39, 0.5);
$background-navbar-2: rgb(0, 61, 39);

/* Preloader */

$preloader-background: #ffffff;
$preloader-color-1: #e20e87;
$preloader-color-2: #e20e87;
$preloader-color-3: #e20e87;

/*Typography*/

$font-family-sans-serif: "Lato",
sans-serif;