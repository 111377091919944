header {
    background-image: url($image-path + "header-bg-mobile.jpg");
    background-position-x: right;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @media (min-width: $screen-sm-min) {
        background-image: url($image-path + "header-bg.jpg");
        background-position-x: center;
    }
    &.article {
        padding-bottom: 120px;
    }
    >.jmod {
        padding-bottom: 120px;
        @media (min-width: $screen-sm-min) {
            bottom: 120px;
            left: 0;
            opacity: 0;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            position: absolute;
            right: 0;
            @include transition-delay(0.5s);
            @include transition-duration(0.4s);
            @include transition-property(bottom, opacity);
            @include transition-timing(ease);
            &.active {
                bottom: 60px;
                opacity: 1;
            }
        }
    }
}

.logo {
    display: inline-block;
    margin: 50px 15px 20px;
    @media (min-width: $screen-sm-min) {
        margin: 50px 0 0;
    }
    a {
        display: inline-block;
    }
}

.navbar-default {
    background-color: transparent;
    border-radius: 0;
    border-width: 0;
    margin: 0;
    min-height: auto;
    @media (min-width: $screen-sm-min) {
        background-color: $background-navbar-1;
        margin-top: 70px;
        padding: 7px 0;
    }
    .navbar-toggle {
        border-radius: 0;
        border-width: 3px;
        padding: 3px 6px;
        &:focus,
        &:hover {
            background-color: transparent;
        }
        i {
            color: $text-color-1;
            font-size: 28px;
        }
    }
    .navbar-collapse {
        background-color: $background-navbar-2;
        border-color: transparent;
        box-shadow: none;
        position: relative;
        z-index: 1;
        @media (min-width: $screen-sm-min) {
            background-color: transparent;
            padding: 0;
        }
    }
    .navbar-header {
        position: absolute;
        right: 0;
        top: -70px;
    }
    .navbar-nav {
        margin-left: 0;
        margin-right: 0;
        >li {
            text-align: center;
            >a {
                border: 2px solid transparent;
                color: $text-color-1;
                display: inline-block;
                font-size: 16px;
                padding: 12px 15px;
                text-transform: uppercase;
                &:focus,
                &:hover {
                    color: $text-color-3;
                }
            }
        }
        .active {
            >a,
            >a:focus,
            >a:hover {
                background-color: transparent;
                border-radius: 25px;
                border: 2px solid $background-color-3;
                color: $text-color-3;
                font-weight: 700;
            }
        }
    }
}

.index-jumbotron {
    @media (min-width: $screen-sm-min) {
        float: right;
        width: 100%;
    }
    @media (min-width: $screen-md-min) {
        width: 80%;
    }
    @media (min-width: $screen-lg-min) {
        width: 60%;
    }
    h1 {
        color: $text-color-3;
        font-size: 48px;
        font-weight: 700;
        line-height: 38px;
        margin: 60px 0 30px 0;
        text-align: center;
        @media (min-width: $screen-sm-min) {
            margin: 0 0 30px 20px;
            text-align: left;
        }
        span {
            color: $text-color-1;
            display: block;
            font-weight: 300;
            line-height: 38px;
            @media (min-width: $screen-sm-min) {
                margin-left: 36px;
            }
        }
    }
    .content {
        background-color: $background-color-3;
        box-shadow: -7px -7px 0 $background-color-4;
        padding: 20px 20px 60px;
        position: relative;
        width: 100%;
        @media (min-width: $screen-sm-min) {
            padding: 20px 300px 20px 20px;
        }
        img {
            display: none;
            @media (min-width: $screen-sm-min) {
                bottom: 0;
                display: block;
                position: absolute;
                right: -40px;
            }
        }
        >p {
            color: $text-color-1;
            font-size: 15px;
            font-weight: 300;
            margin: 0;
            @media (min-width: $screen-sm-min) {
                height: 240px;
                overflow-y: auto;
                padding-right: 20px;
            }
        }
        .info {
            bottom: -60px;
            left: 0;
            margin: 0 auto;
            padding-left: 50px;
            position: absolute;
            right: 0;
            width: 250px;
            @media (min-width: $screen-sm-min) {
                bottom: -10px;
                left: auto;
                right: 0;
            }
            &::before {
                background-color: $background-color-7;
                box-shadow: 7px 7px 0 $background-color-7a;
                content: "";
                height: 110px;
                left: 0;
                margin: 0 auto;
                position: absolute;
                right: 0;
                top: -13px;
                transform: skewX(20deg);
                width: 250px;
            }
            .h2,
            p,
            a {
                color: $text-color-1;
                font-weight: 700;
                margin: 0;
                position: relative;
            }
            i {
                color: $text-color-1;
                font-size: 32px;
                left: 10px;
                position: absolute;
                top: 8px;
            }
            .h2 {
                font-size: 24px;
                font-weight: 300;
                text-transform: uppercase;
            }
            p {
                font-size: 16px;
            }
            a {
                font-size: 28px;
            }
        }
    }
}