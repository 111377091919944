.violence {
    padding-top: 30px;
    position: relative;
    &::before {
        display: none;
        @media (min-width: $screen-md-min) {
            background-image: url($image-path + "line.png");
            background-repeat: no-repeat;
            content: "";
            display: block;
            height: 251px;
            left: 45%;
            margin: 0 auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 349px;
        }
        @media (min-width: $screen-lg-min) {
            left: 25%;
        }
    }
    @media (min-width: $screen-sm-min) {
        padding-top: 80px;
    }
    h2,
    .h2 {
        color: $text-color-4;
        font-size: 48px;
        font-weight: 700;
        line-height: 42px;
        text-align: center;
        @media (min-width: $screen-sm-min) {
            max-width: 450px;
            text-align: left;
        }
    }
    p {
        font-weight: 300;
        margin-top: 20px;
        text-align: center;
        @media (min-width: $screen-sm-min) {
            text-align: left;
        }
    }
    .continents {
        @media (min-width: $screen-sm-min) {
            margin: 60px 0;
        }
        >div {
            margin-top: 30px;
        }
        figcaption {
            >p {
                color: $text-color-4;
                font-size: 18px;
                font-weight: 300;
                line-height: 22px;
                text-align: center;
                >span {
                    display: block;
                    font-weight: 700;
                    margin: 15px 0 0;
                }
            }
        }
    }
}

.women {
    text-align: center;
    >div {
        @media (min-width: $screen-sm-min) {
            display: inline-block;
            float: none;
            text-align: left;
            vertical-align: middle;
        }
        .h2 {
            @media (min-width: $screen-sm-min) {
                max-width: 100%;
            }
        }
    }
    .victims {
        margin: 60px 0;
        >div {
            @media (max-width: $screen-xs-max) {
                width: 100%;
                margin: 20px 0;
            }
        }
    }
}

.charts {
    margin: 60px -15px;
    .collected-information,
    .enacted-act {
        color: $text-color-1;
        padding: 15px 20px;
        @media (min-width: $screen-md-min) {
            padding: 15px 60px;
        }
        p {
            font-weight: 600;
            margin-top: 0;
        }
        ul {
            list-style-image: none;
            list-style-position: inside;
            list-style-type: none;
            padding: 0;
        }
        li {
            text-align: center;
            @media (min-width: $screen-md-min) {
                text-align: left;
            }
        }
    }
    .collected-information {
        background-color: $background-color-3;
        box-shadow: -7px -7px 0 $background-color-4;
        @media (min-width: $screen-md-min) {
            width: 575px;
        }
        @media (min-width: $screen-lg-min) {
            width: 693px;
        }
        span {
            display: inline-block;
            vertical-align: middle;
        }
        .bar-chart-1,
        .bar-chart-2 {
            border-radius: 10px;
            display: block;
            height: 18px;
            margin: 5px auto 0 auto;
            width: 0;
            @include transition-delay(0.5s);
            @include transition-duration(1s);
            @include transition-property(width);
            @include transition-timing(ease);
            @media (min-width: $screen-md-min) {
                display: inline-block;
                margin: 0 5px;
            }
        }
        .bar-chart-1 {
            background-color: $background-color-chart-bar-1;
            &.active {
                width: 200px;
            }
        }
        .bar-chart-2 {
            background-color: $background-color-chart-bar-2;
            &.active {
                width: 99px;
            }
        }
    }
    .enacted-act {
        background-color: $background-color-5;
        box-shadow: -7px -7px 0 $background-color-6;
        margin-top: 60px;
        @media (min-width: $screen-md-min) {
            left: -30px;
            position: relative;
            width: 404px;
            z-index: -1;
        }
        @media (min-width: $screen-lg-min) {
            width: 487px;
        }
    }
}

.most-searched {
    background-image: url($image-path + "footer-bg.jpg");
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 30px;
    position: relative;
    @media (min-width: $screen-sm-min) {
        padding-top: 80px;
    }
    h2 {
        color: $text-color-2;
        font-size: 48px;
        font-weight: 700;
        line-height: 42px;
        margin-bottom: 30px;
        text-align: center;
        span {
            color: $text-color-1;
            font-weight: 300;
        }
    }
    .icons {
        margin-bottom: 50px;
        .item {
            float: left;
            margin-top: 30px;
            padding-left: 15px;
            padding-right: 15px;
            text-align: center;
            width: 50%;
            @media (max-width: $screen-xs-max) {
                width: 100%;
            }
            @media (min-width: $screen-sm-min) {
                width: 33.33333%;
            }
            @media (min-width: $screen-lg-min) {
                width: 12.5%;
            }
            &:nth-child(even) {
                @media (min-width: $screen-lg-min) {
                    height: 300px;
                }
                >figure {
                    @media (min-width: $screen-lg-min) {
                        position: relative;
                        top: 130px;
                        &::before,
                        &::after {
                            background-color: $background-color-1;
                            content: "";
                            height: 2px;
                            position: absolute;
                            top: -1px;
                            width: 80px;
                        }
                        &::before {
                            left: -60px;
                            -webkit-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }
                        &::after {
                            right: -60px;
                            -webkit-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
            &:nth-last-child(1) {
                >figure {
                    @media (min-width: $screen-lg-min) {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
            figure {
                >a {
                    border-radius: 50%;
                    display: inline-block;
                    overflow: hidden;
                    position: relative;
                    border: 2px solid $background-color-5;
                    &::before {
                        background-color: $background-color-4a;
                        bottom: 0;
                        color: $text-color-1;
                        content: "Więcej";
                        font-size: 16px;
                        left: 0;
                        line-height: 123px;
                        display: none;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        text-transform: lowercase;
                        top: 0;
                        @media (min-width: $screen-lg-min) {
                            line-height: 116px;
                        }
                    }
                    &:hover::before {
                        display: block;
                    }
                }
                figcaption {
                    h2 {
                        line-height: 0;
                        margin-bottom: 0;
                        margin-top: 5px;
                        a {
                            color: $text-color-1;
                            display: inline-block;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 1.2;
                        }
                    }
                }
            }
        }
    }
}

.read-more {
    h2 {
        color: $text-color-4;
        font-size: 48px;
        font-weight: 700;
        line-height: 42px;
        margin-bottom: 30px;
        margin-top: 0;
        text-align: center;
    }
    display: none;
    padding-bottom: 60px;
    padding-top: 60px;
    .content {
        p {
            font-size: 14px;
        }
    }
}