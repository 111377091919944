body {
    font-family: $font-family-sans-serif;
    font-size: 16px;
    color: $text-color-2;
    background-color: $background-color-1;
}

a {
    color: $text-color-3;
    &:focus,
    &:hover {
        color: $text-color-3;
    }
}

.scroll {
    display: none;
    @media (min-width: $screen-sm-min) {
        background-image: url($image-path + "scroll-arrows.png");
        background-position-x: center;
        background-position-y: 0;
        background-repeat: no-repeat;
        bottom: 20px;
        display: block;
        height: 66px;
        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
        width: 48px;
        z-index: 1;
        .violence & {
            background-position-y: -66px;
        }
    }
    @media (min-width: $screen-md-min) {
        display: block;
        right: 755px;
    }
    @media (min-width: $screen-lg-min) {
        right: 411px;
    }
    &::after {
        background-image: url($image-path + "scroll-arrows.png");
        background-position-x: center;
        background-position-y: -66px;
        background-repeat: no-repeat;
        bottom: -106px;
        content: "";
        height: 66px;
        left: 0;
        position: absolute;
        width: 48px;
        .violence & {
            background-position-y: 0;
        }
    }
}

.btn-read-more {
    margin-bottom: 50px;
    .btn-info {
        background-color: $background-color-2;
        border-radius: 22px;
        border-width: 0;
        color: $text-color-1;
        font-size: 16px;
        font-weight: 800;
        padding: 18px 45px;
        text-transform: uppercase;
        &:hover {
            background-color: $background-color-3;
        }
    }
}

.alert {
    border-radius: 0;
    margin: 30px 0;
    padding: 15px;
    h2 {
        color: $text-color-1 !important;
        margin: 70px 0 10px;
        text-align: center;
        @media (min-width: $screen-sm-min) {
            margin: 0 0 10px;
            text-align: left;
        }
    }
    p {
        margin: 0;
        text-align: center;
        @media (min-width: $screen-sm-min) {
            text-align: left;
        }
    }
    i {
        font-size: 72px;
        height: 72px;
        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
        top: 15px;
        width: 62px;
        @media (min-width: $screen-sm-min) {
            bottom: 0;
            left: 35px;
            margin: auto 0;
            right: auto;
            top: 0;
        }
        @media (min-width: $screen-md-min) {
            left: 50px;
        }
        @media (min-width: $screen-lg-min) {
            left: 70px;
        }
    }
    .embed-responsive {
        margin-top: 15px;
    }
}

.alert-info {
    background-color: $background-color-3;
    border-width: 0;
    box-shadow: -7px -7px 0 $background-color-4;
    color: $text-color-1;
    position: relative;
}